import request from "@src/utils/request";

export const addCloudWatch = (data: {
  ConfigCode: string;
  accountId: string;
  Region: string;
}) => {
  return request({
    url: "/alarm_auto",
    method: "POST",
    data,
  });
};
