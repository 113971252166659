import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Modal,
  message,
  Form,
  Divider,
} from "antd";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useSearchParams } from "react-router-dom";
import { getDetailList, addConfig, editConfig, deleteConfig } from "./service";
import BreadcrumbCom from "@src/components/Breadcrumb";
import style from "./index.module.less";

interface DataType {
  Statistic: string;
  uuid: string;
  NameSpace: string;
  TreatMissingData?: string;
  ConfigCode: string;
  Period: string;
  Level: string;
  MetricName: string;
  Unit: string;
  ComparisonOperator: string;
  Threshold: string;
  EvaluationPeriods: string;
}

const { Search } = Input;

const CloudWatchDetailPage = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [initDataSource, setInitDataSource] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const currentRow = useRef<DataType>();
  const [searchParams] = useSearchParams();

  const getList = useCallback(() => {
    if (!searchParams.get("configcode")) return;
    setLoading(true);
    getDetailList({
      ConfigCode: searchParams?.get("configcode") || "",
    })
      .then((res) => {
        setLoading(false);
        setDataSource(res?.data || []);
        setInitDataSource(res?.data || []);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    getList();
  }, [getList]);

  const onSearch = useCallback(
    (value: string) => {
      const filter = value.trim().toLocaleLowerCase();
      if (!filter) {
        setDataSource(initDataSource);
        return;
      }
      const filterResult = initDataSource.filter((item: DataType) =>
        item.NameSpace?.toLocaleLowerCase().includes(filter)
      );
      setDataSource(filterResult);
    },
    [initDataSource]
  );

  const reset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const showAddModal = useCallback(
    (isEdit?: boolean, row?: DataType) => {
      setIsEdit(!!isEdit);
      if (isEdit) {
        currentRow.current = row;
        form.setFieldsValue(row);
      }
      setAddOpen(true);
    },
    [form]
  );

  const saveAdd = useCallback(
    (values: any) => {
      addConfig([{ ConfigCode: searchParams.get("configcode"), ...values }])
        .then((res: any) => {
          setAddOpen(false);
          setConfirmLoading(false);
          message.success(res.message);
          getList();
          reset();
        })
        .catch(() => {
          setConfirmLoading(false);
        });
    },
    [getList, reset, searchParams]
  );
  const saveEdit = useCallback(
    (values: any) => {
      editConfig({ ...currentRow.current, ...values })
        .then((res: any) => {
          setAddOpen(false);
          setConfirmLoading(false);
          message.success(res.message);
          getList();
          reset();
        })
        .catch(() => {
          setConfirmLoading(false);
        });
    },
    [getList, reset]
  );

  const saveConfirm = useCallback(
    (values: any) => {
      setConfirmLoading(true);
      if (!isEdit) {
        saveAdd(values);
      } else {
        saveEdit(values);
      }
    },
    [isEdit, saveAdd, saveEdit]
  );

  const showDeleteModal = useCallback((row: DataType) => {
    currentRow.current = row;
    setDeleteOpen(true);
  }, []);

  const saveDelete = useCallback(() => {
    setConfirmLoading(true);
    deleteConfig(currentRow.current)
      .then((res: any) => {
        message.success(res?.message);
        setConfirmLoading(false);
        setDeleteOpen(false);
        getList();
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  }, [getList]);

  const columns: ColumnsType<DataType> = [
    {
      title: "NameSpace",
      dataIndex: "NameSpace",
      sorter: (a, b) => a.NameSpace.localeCompare(b.NameSpace),
    },
    {
      title: "MetricName",
      dataIndex: "MetricName",
    },
    {
      title: "Level",
      dataIndex: "Level",
    },
    {
      title: "ComparisonOperator",
      dataIndex: "ComparisonOperator",
    },
    {
      title: "EvaluationPeriods",
      dataIndex: "EvaluationPeriods",
    },
    {
      title: "Period",
      dataIndex: "Period",
    },
    {
      title: "Statistic",
      dataIndex: "Statistic",
    },
    {
      title: "Threshold",
      dataIndex: "Threshold",
      sorter: (a, b) => Number(a.Threshold) - Number(b.Threshold),
    },
    {
      title: "TreatMissingData",
      dataIndex: "TreatMissingData",
    },
    {
      title: "Unit",
      dataIndex: "Unit",
    },
    {
      title: "操作",
      key: "action",
      width: "200px",
      fixed: "right",
      render: (_, record) => (
        <Space size={5}>
          <Button
            type="primary"
            onClick={() => showAddModal(true, record)}
            disabled={searchParams.get("configcode") === "Default"}
          >
            编辑
          </Button>
          <Button
            danger
            onClick={() => showDeleteModal(record)}
            disabled={searchParams.get("configcode") === "Default"}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbCom
        items={[
          {
            title: (
              <>
                <HomeOutlined />
                <span> 告警管理</span>
              </>
            ),
          },
          {
            path: "/cloudWatch",
            title: "项目管理",
          },
          {
            title: `${searchParams?.get("configcode")}-配置详情`,
          },
        ]}
      />
      <Divider className={style.devider} />
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div className={style.filterBox}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            disabled={searchParams.get("configcode") === "Default"}
            onClick={() => showAddModal()}
          >
            新建
          </Button>
          <Search
            className={style.filterWidth}
            placeholder="请输入NameSpace"
            allowClear
            enterButton="查询"
            size="large"
            onSearch={onSearch}
          />
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey="uuid"
          scroll={{ x: true }}
          loading={loading}
          pagination={{
            total: dataSource.length,
            showTotal: (total) => `共 ${total} 条`,
          }}
        />
      </Space>
      <Modal
        title={`${isEdit ? "编辑" : "新建"}配置`}
        cancelText="取消"
        okText="确定"
        centered
        open={addOpen}
        onCancel={() => {
          setAddOpen(false);
          reset();
        }}
        footer={null}
        bodyStyle={{ paddingTop: "20px" }}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={saveConfirm}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="NameSpace"
            name="NameSpace"
            rules={[
              { required: true, message: "请输入NameSpace!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="MetricName"
            name="MetricName"
            rules={[
              { required: true, message: "请输入MetricName!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="Level"
            name="Level"
            rules={[
              { required: true, message: "请输入Level!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ComparisonOperator"
            name="ComparisonOperator"
            rules={[
              { required: true, message: "请输入ComparisonOperator!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="EvaluationPeriods"
            name="EvaluationPeriods"
            rules={[
              { required: true, message: "请输入EvaluationPeriods!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Period"
            name="Period"
            rules={[
              { required: true, message: "请输入Period!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Statistic"
            name="Statistic"
            rules={[
              { required: true, message: "请输入Statistic!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Threshold"
            name="Threshold"
            rules={[
              { required: true, message: "请输入Threshold!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="TreatMissingData"
            name="TreatMissingData"
            rules={[
              { required: true, message: "请输入TreatMissingData!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Unit"
            name="Unit"
            rules={[
              { required: true, message: "请输入Unit!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              {!isEdit && <Button onClick={reset}>重置</Button>}
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="删除提示"
        centered
        cancelText="取消"
        okText="确定"
        open={deleteOpen}
        onOk={() => saveDelete()}
        confirmLoading={confirmLoading}
        onCancel={() => setDeleteOpen(false)}
      >
        <p>确认删除【{currentRow.current?.NameSpace}】吗？</p>
      </Modal>
    </>
  );
};

export default CloudWatchDetailPage;
