import request from "@src/utils/request";

export const getDetailList = (params: { ConfigCode: string }) => {
  return request({
    url: "/config_manager/get_config_items",
    method: "GET",
    params,
  });
};

export const addConfig = (data: any) => {
  return request({
    url: "/config_manager/add_config_items",
    method: "POST",
    data,
  });
};

export const editConfig = (data: any) => {
  return request({
    url: "/config_manager/edit_config_items",
    method: "POST",
    data,
  });
};

export const deleteConfig = (data: any) => {
  return request({
    url: "/config_manager/del_config_items",
    method: "POST",
    data,
  });
};
