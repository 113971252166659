import React from "react";
import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import "./index.css";
import Router from "./router";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <BrowserRouter>
  <HashRouter>
    <ConfigProvider locale={zh_CN}>
      <Router />
    </ConfigProvider>
  </HashRouter>
  // </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
