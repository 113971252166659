import axios from "axios";
import React from "react";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { setLogout } from "./index";

const { confirm } = Modal;

const myAxios = axios.create({
  baseURL: "https://j4jco4z0ji.execute-api.cn-north-1.amazonaws.com.cn/prod",
  timeout: 50000,
});

myAxios.interceptors.request.use((config) => {
  const userToken = localStorage.getItem("token");
  if (userToken) {
    config.headers.Authorization = `Bearer ${userToken}`;
  }
  return config;
});

myAxios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response?.status === 403) {
      Modal.destroyAll();
      confirm({
        title: error.response?.data?.message,
        icon: React.createElement(ExclamationCircleFilled),
        content: "是否跳转到登录页面？",
        onOk() {
          setLogout();
          window.location.href = `${window.location.origin}`;
        },
      });
    } else {
      message.error(
        error.response?.data?.message || "服务器开小差了，请稍后重试～"
      );
    }

    return Promise.reject(error.response);
  }
);

export default myAxios;
