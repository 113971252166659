import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Space,
  Input,
  Modal,
  message,
  Form,
  Divider,
  Spin,
} from "antd";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import BreadcrumbCom from "@src/components/Breadcrumb";
import { getConfigList, addConfig, deleteConfig } from "./service";
import style from "./index.module.less";

const CloudWatchPage = () => {
  const [dataSource, setDataSource] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const currentRow = useRef<string>();

  const navigate = useNavigate();

  const getList = useCallback(() => {
    setLoading(true);
    getConfigList()
      .then((res) => {
        setLoading(false);
        setDataSource(res?.data || []);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  const reset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const showAddModal = useCallback(() => {
    setAddOpen(true);
  }, []);

  const saveAdd = useCallback(
    (values: any) => {
      setConfirmLoading(true);
      addConfig(values)
        .then((res: any) => {
          setAddOpen(false);
          setConfirmLoading(false);
          getList();
          message.success(res?.message || "提交成功～");
          reset();
        })
        .catch(() => {
          setConfirmLoading(false);
        });
    },
    [getList, reset]
  );

  const showDeleteModal = useCallback((row: string) => {
    currentRow.current = row;
    setDeleteOpen(true);
  }, []);

  const saveDelete = useCallback(() => {
    if (!currentRow.current) return;
    setConfirmLoading(true);
    deleteConfig({
      ConfigCode: currentRow.current,
    })
      .then((res: any) => {
        message.success(res?.message || "删除成功～");
        setConfirmLoading(false);
        setDeleteOpen(false);
        getList();
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  }, [getList]);

  const gotoDetail = useCallback(
    (ConfigCode: string) => {
      navigate(`/cloudWatch/detail?configcode=${ConfigCode}`);
    },
    [navigate]
  );

  return (
    <>
      <BreadcrumbCom
        items={[
          {
            title: (
              <>
                <HomeOutlined />
                <span> 告警管理</span>
              </>
            ),
          },
          {
            title: "项目管理",
          },
        ]}
      />
      <Divider className={style.devider} />
      {loading ? (
        <div className={style.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <div className={style.content}>
          {dataSource?.map((item, index: number) => {
            return (
              <div className={style.contentItem} key={index.toString()}>
                <div className={style.title}>{item}</div>
                {item === "Default" && (
                  <div className={style.defaultTips}>
                    默认项目配置，无特殊定制化需求，都是用该配置创建告警。
                  </div>
                )}

                <div className={style.operation}>
                  <Space>
                    <Button type="primary" onClick={() => gotoDetail(item)}>
                      查看
                    </Button>
                    <Button
                      danger
                      onClick={() => showDeleteModal(item)}
                      disabled={item === "Default"}
                    >
                      删除
                    </Button>
                  </Space>
                </div>
              </div>
            );
          })}

          <div
            className={`${style.contentItem} ${style.addBtn}`}
            onClick={() => showAddModal()}
          >
            <PlusOutlined className={style.addIcon} />
          </div>
        </div>
      )}

      <Modal
        title="新建项目"
        cancelText="取消"
        okText="确定"
        centered
        open={addOpen}
        onCancel={() => {
          setAddOpen(false);
          reset();
        }}
        footer={null}
        bodyStyle={{ paddingTop: "20px" }}
      >
        <Form
          name="addWatch"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={saveAdd}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="项目名称"
            name="ConfigCode"
            rules={[
              { required: true, message: "请输入项目名称!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button onClick={reset}>重置</Button>
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="删除提示"
        centered
        cancelText="取消"
        okText="确定"
        open={deleteOpen}
        onOk={() => saveDelete()}
        confirmLoading={confirmLoading}
        onCancel={() => setDeleteOpen(false)}
      >
        <p>确认删除【{currentRow.current}】吗？</p>
      </Modal>
    </>
  );
};

export default CloudWatchPage;
