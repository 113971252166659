import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Modal,
  message,
  Form,
  Divider,
} from "antd";
import { PlusOutlined, HomeOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import {
  getAccountList,
  addAccount,
  editAccount,
  deleteAccount,
} from "./service";
import BreadcrumbCom from "@src/components/Breadcrumb";
import style from "./index.module.less";

interface DataType {
  Account: string;
  uuid?: string;
  Cps?: string;
  Email?: string;
  ProjectCode?: string;
}

const { Search } = Input;

const ProjectPage = () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [initDataSource, setInitDataSource] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const currentRow = useRef<DataType>();

  const getList = useCallback(() => {
    setLoading(true);
    getAccountList()
      .then((res) => {
        setLoading(false);
        setDataSource(res?.data || []);
        setInitDataSource(res?.data || []);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getList();
  }, [getList]);

  const onSearch = useCallback(
    (value: string) => {
      const filter = value.trim().toLocaleLowerCase();
      if (!filter) {
        setDataSource(initDataSource);
        return;
      }
      const filterResult = initDataSource.filter(
        (item: DataType) =>
          item.Account.toLocaleLowerCase().includes(filter) ||
          item.ProjectCode?.toLocaleLowerCase().includes(filter)
      );
      setDataSource(filterResult);
    },
    [initDataSource]
  );

  const reset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const showAddModal = useCallback(
    (isEdit?: boolean, row?: DataType) => {
      setIsEdit(!!isEdit);
      if (isEdit) {
        currentRow.current = row;
        form.setFieldsValue(row);
      }
      setAddOpen(true);
    },
    [form]
  );

  const saveAdd = useCallback(
    (values: any) => {
      addAccount(values)
        .then((res: any) => {
          setAddOpen(false);
          setConfirmLoading(false);
          message.success(res?.message || "提交成功～");
          reset();
          getList();
        })
        .catch(() => {
          setConfirmLoading(false);
        });
    },
    [reset, getList]
  );

  const saveEdit = useCallback(
    (values: any) => {
      editAccount({ uuid: currentRow.current?.uuid, ...values })
        .then((res: any) => {
          setAddOpen(false);
          setConfirmLoading(false);
          message.success(res?.message || "提交成功～");
          reset();
          getList();
        })
        .catch(() => {
          setConfirmLoading(false);
        });
    },
    [reset, getList]
  );

  const saveConfirm = useCallback(
    (values: any) => {
      setConfirmLoading(true);
      if (isEdit) {
        saveEdit(values);
      } else {
        saveAdd(values);
      }
    },
    [saveAdd, saveEdit, isEdit]
  );

  const showDeleteModal = useCallback((row: DataType) => {
    currentRow.current = row;
    setDeleteOpen(true);
  }, []);

  const saveDelete = useCallback(() => {
    setConfirmLoading(true);
    deleteAccount(currentRow.current)
      .then((res: any) => {
        message.success(res?.message || "删除成功～");
        setConfirmLoading(false);
        setDeleteOpen(false);
        getList();
      })
      .catch(() => {
        setConfirmLoading(false);
      });
  }, [getList]);

  const columns: ColumnsType<DataType> = [
    {
      title: "项目名称",
      dataIndex: "ProjectCode",
    },
    {
      title: "项目账号",
      dataIndex: "Account",
    },
    {
      title: "项目类型",
      dataIndex: "Cps",
    },
    {
      title: "邮箱",
      dataIndex: "Email",
    },
    {
      title: "操作",
      key: "action",
      width: "200px",
      fixed: "right",
      render: (_, record) => (
        <Space size={5}>
          <Button type="primary" onClick={() => showAddModal(true, record)}>
            编辑
          </Button>
          <Button danger onClick={() => showDeleteModal(record)}>
            删除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbCom
        items={[
          {
            title: (
              <>
                <HomeOutlined />
                <span> 项目管理</span>
              </>
            ),
          },
          {
            title: "项目账号管理",
          },
        ]}
      />
      <Divider className={style.devider} />
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div className={style.filterBox}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => showAddModal()}
          >
            新建
          </Button>
          <Search
            className={style.filterWidth}
            placeholder="请输入项目名称、项目账号"
            allowClear
            enterButton="查询"
            size="large"
            onSearch={onSearch}
          />
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          rowKey="uuid"
          loading={loading}
          scroll={{ x: true }}
          pagination={{
            total: dataSource.length,
            showTotal: (total) => `共 ${total} 条`,
          }}
        />
      </Space>
      <Modal
        title={`${isEdit ? "编辑" : "新建"}项目信息`}
        cancelText="取消"
        okText="确定"
        centered
        open={addOpen}
        onCancel={() => {
          setAddOpen(false);
          reset();
        }}
        footer={null}
        bodyStyle={{ paddingTop: "20px" }}
      >
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={saveConfirm}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="项目代码"
            name="ProjectCode"
            rules={[
              { required: true, message: "请输入项目代码!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="项目账号"
            name="Account"
            rules={[
              { required: true, message: "请输入项目账号!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="项目类型"
            name="Cps"
            rules={[
              { required: true, message: "请输入项目类型!" },
              { max: 50, message: "请输入1到50个字符!" },
            ]}
          >
            <Input disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="联系邮箱"
            name="Email"
            rules={[{ required: true, message: "请输入联系邮箱!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 5, span: 19 }}>
            <Space>
              {!isEdit && <Button onClick={reset}>重置</Button>}
              <Button type="primary" htmlType="submit" loading={confirmLoading}>
                提交
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="删除提示"
        centered
        cancelText="取消"
        okText="确定"
        open={deleteOpen}
        onOk={() => saveDelete()}
        confirmLoading={confirmLoading}
        onCancel={() => setDeleteOpen(false)}
      >
        <p>确认删除【{currentRow.current?.ProjectCode}】吗？</p>
      </Modal>
    </>
  );
};

export default ProjectPage;
