import request from "@src/utils/request";

export const getAccountList = () => {
  return request({
    url: "/alarm_account_manager/get_all_account",
    method: "GET",
  });
};
export const addAccount = (data: any) => {
  return request({
    url: "/alarm_account_manager/add_account_info",
    method: "POST",
    data,
  });
};
export const editAccount = (data: any) => {
  return request({
    url: "/alarm_account_manager/edit_account_info",
    method: "POST",
    data,
  });
};
export const deleteAccount = (data: any) => {
  return request({
    url: "/alarm_account_manager/del_account_info",
    method: "POST",
    data,
  });
};
