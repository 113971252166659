import React, { useCallback, useEffect, useState } from "react";
import { Button, Space, message, Form, Divider, Select } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { addCloudWatch } from "./service";
import { getConfigList } from "../cloudWatch/service";
import { getAccountList } from "../project/service";
import BreadcrumbCom from "@src/components/Breadcrumb";
import style from "./index.module.less";

interface ProjectDataType {
  Account: string;
  uuid?: string;
  Cps?: string;
  Email?: string;
  ProjectCode?: string;
}

type OptionType = {
  label: string;
  value: string;
  uuid: string;
};

const AddCouldWatchPage = () => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [projectOption, setProjectOption] = useState([]);
  const [accountOption, setAccontOption] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;

  const getProject = useCallback(() => {
    getConfigList().then((res) => {
      setProjectOption(res?.data || []);
    });
  }, []);

  const getAccount = useCallback(() => {
    getAccountList().then((res) => {
      const list = res?.data?.map((item: ProjectDataType) => {
        return {
          label: `${item.ProjectCode}-${item.Account}`,
          value: `${item.Account}`,
          uuid: item?.uuid,
        };
      });
      setAccontOption(list || []);
    });
  }, []);

  useEffect(() => {
    getProject();
    getAccount();
  }, [getProject, getAccount]);

  const reset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const saveConfirm = useCallback(
    (values: any) => {
      setConfirmLoading(true);
      addCloudWatch(values)
        .then((res: any) => {
          message.success(res?.message);
          setConfirmLoading(false);
          reset();
        })
        .catch((res: any) => {
          setConfirmLoading(false);
        });
    },
    [reset]
  );

  return (
    <>
      <BreadcrumbCom
        items={[
          {
            title: (
              <>
                <HomeOutlined />
                <span> 添加项目告警</span>
              </>
            ),
          },
        ]}
      />
      <Divider className={style.devider} />
      <Form
        name="basic"
        labelAlign="right"
        labelCol={{ span: 6 }}
        style={{ maxWidth: "600px" }}
        initialValues={{ remember: true }}
        onFinish={saveConfirm}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="配置代码"
          name="ConfigCode"
          rules={[{ required: true, message: "请选择一个配置代码!" }]}
        >
          <Select allowClear>
            {projectOption.map((item: string[], index: number) => (
              <Option value={item} key={index}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="项目账号"
          name="accountId"
          rules={[{ required: true, message: "请选择一个项目账号!" }]}
        >
          <Select allowClear showSearch optionFilterProp="children">
            {accountOption.map((item: OptionType) => (
              <Option value={item.value} key={item.uuid}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="Region"
          label="Region"
          rules={[{ required: true, message: "请选择一个Region" }]}
        >
          <Select allowClear>
            <Option value="cn-north-1">cn-north-1</Option>
            <Option value="cn-northwest-1">cn-northwest-1</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Space>
            <Button onClick={reset}>重置</Button>
            <Button type="primary" htmlType="submit" loading={confirmLoading}>
              提交
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddCouldWatchPage;
