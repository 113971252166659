import request from "@src/utils/request";

export const getConfigList = () => {
  return request({
    url: "/config_manager/get_all_config",
    method: "GET",
  });
};

export const addConfig = (data: { ConfigCode: string }) => {
  return request({
    url: "/config_manager/add_config",
    method: "POST",
    data,
  });
};

export const deleteConfig = (data: { ConfigCode: string }) => {
  return request({
    url: "/config_manager/del_config",
    method: "POST",
    data,
  });
};
