import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  TeamOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, MenuProps, Space, theme } from "antd";
import logo from "@src/assets/images/logo.png";
import LoginPage from "@src/pages/login";
import ProjectPage from "../pages/project";
import CloudWatchPage from "../pages/cloudWatch";
import CloudWatchDetailPage from "../pages/cloudWatchDetail";
import AddCouldWatchPage from "../pages/addCouldWatch";
import { getUser } from "@src/pages/login/service";
import style from "./index.module.less";
import { getIsLogin, setLogout } from "@src/utils/index";

const { Header, Sider, Content } = Layout;

const Router = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<{
    username: string;
    email: string;
  }>({ username: "", email: "" });

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const isLogin = getIsLogin();

  const changeMenu = useCallback(
    (data: any) => {
      navigate(data.key);
    },
    [navigate]
  );

  const currentPath = useMemo(() => {
    if (!isLogin) return;
    let currentPathKey = "";
    if (window.location.hash) {
      currentPathKey = `/${window.location.hash?.split("/")[1] || "project"}`;
    } else {
      currentPathKey = `/${
        window.location.pathname?.split("/")[1] || "project"
      }`;
    }
    return [currentPathKey];
  }, [isLogin]);

  const getUserInfo = useCallback(() => {
    getUser().then((res) => {
      setUserInfo(res.data);
    });
  }, []);

  const logout = useCallback(() => {
    setLogout();
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (isLogin) {
      getUserInfo();
    }
  }, [getUserInfo, isLogin]);

  const items: MenuProps["items"] = [
    {
      label: userInfo.email,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div onClick={logout} className={style.quitBtn}>
          退出登录
        </div>
      ),
      key: "3",
    },
  ];

  return (
    <>
      {!isLogin ? (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      ) : (
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className={style.logo}>
              <img src={logo} alt="光环有云" />
            </div>
            <Menu
              theme="dark"
              mode="inline"
              defaultOpenKeys={["项目管理"]}
              defaultSelectedKeys={currentPath}
              onClick={changeMenu}
              items={[
                {
                  key: "项目管理",
                  icon: <TeamOutlined />,
                  label: "项目管理",
                  children: [
                    {
                      key: "/project",
                      label: "项目账号管理",
                    },
                  ],
                },
                {
                  key: "/cloudWatch",
                  icon: <VideoCameraOutlined />,
                  label: "告警管理",
                },
                {
                  key: "/addCloudWatch",
                  icon: <VideoCameraAddOutlined />,
                  label: "添加项目告警",
                },
              ]}
            />
          </Sider>
          <Layout className={style["site-layout"]}>
            <Header style={{ padding: 0, background: colorBgContainer }}>
              <div className={style.header}>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: style.trigger,
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
                <div>
                  <Dropdown menu={{ items }}>
                    <div>
                      <Space>
                        <UserOutlined />
                        {userInfo.username}
                        <DownOutlined />
                      </Space>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content
              className={style.content}
              style={{ background: colorBgContainer }}
            >
              <div style={{ overflowX: "auto" }}>
                <Routes>
                  <Route path="/project" element={<ProjectPage />} />
                  <Route path="/cloudWatch" element={<CloudWatchPage />} />
                  <Route
                    path="/cloudWatch/detail"
                    element={<CloudWatchDetailPage />}
                  />
                  <Route
                    path="/addCloudWatch"
                    element={<AddCouldWatchPage />}
                  />
                  <Route path="*" element={<ProjectPage />} />
                </Routes>
              </div>
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default Router;
