import React, { useCallback, FC, ReactNode } from "react";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

type ItemType = {
  path?: string;
  title: ReactNode;
};

type Props = {
  items: ItemType[];
};

const BreadcrumbCom: FC<Props> = ({ items }) => {
  const navigate = useNavigate();

  const itemRender = useCallback(
    (item: any, params: any, items: any, paths: any) => {
      const last = items.indexOf(item) === items.length - 1;
      return last || !item?.path ? (
        <span>{item.title}</span>
      ) : (
        <span
          onClick={(e) => {
            e.preventDefault();
            navigate(`/${paths}`);
          }}
        >
          {item.title}
        </span>
      );
    },
    [navigate]
  );

  return <Breadcrumb itemRender={itemRender} items={items} />;
};

export default BreadcrumbCom;
