import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import logo from "@src/assets/images/logo2.png";
import { saveLogin } from "./service";
import style from "./index.module.less";

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const login = (values: any) => {
    setLoading(true);
    saveLogin(values)
      .then((res: any) => {
        localStorage.setItem("isLogin", "true");
        localStorage.setItem("token", res?.data);
        navigate("/project");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.logoBox}>
          <img src={logo} alt="" />
          <span>L2C项目管理平台</span>
        </div>
        <Form
          name="basic"
          className={style.loginForm}
          initialValues={{ remember: true }}
          onFinish={login}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "请输入你的用户名!" },
              { max: 30, message: "请输入1到30个字符!" },
            ]}
          >
            <Input placeholder="请输入你的用户名" allowClear />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "请输入用户密码!" },
              { max: 30, message: "请输入1到30个字符!" },
            ]}
          >
            <Input.Password placeholder="请输入你的密码" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
