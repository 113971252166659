import request from "@src/utils/request";

export const saveLogin = (data: { usename: string; passwored: string }) => {
  return request({
    url: "/auth/login",
    method: "POST",
    data,
  });
};

export const getUser = () => {
  return request({
    url: "/auth/check_token",
    method: "POST",
  });
};
